.headLineL {
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 41px;
}

.headLineS{
  font-style: normal;
  font-weight: 600;
  font-size: 1.53125rem;
  line-height: 33px;
  color: #666666;
}
