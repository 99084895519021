@import "mixin";
@import "function";
@import "fonts";

$base_font_size: 12px;
$base_font_size_lg: 16px;


// quasar theme
$primary: #536dfe;
$secondary: #ff5c93;
$accent: #9c27b0;
$positive: #6DD0A3;
$negative: #ec6893;
$info: #9013fe;
$warning: #f6c370;

$greyTint: #707070;
$grey-11: $greyTint;

$body-bg-dark: #13131a;
$body-bg: #f6f7ff !default;
$content-padding: 24px;

$box-shadow: 0 3px 11px 0 #e8eafc, 0 3px 3px -2px #b2b2b21a,
0 1px 8px 0 #9a9a9a1a;

$colors: (
  light: (
    bg-color: $body-bg,
    greyTint: $greyTint,
    boxShadow: $box-shadow,
    white-bg-color: white,
    notEvaluate:#d0d0d0,
  ),
  dark: (
    bg-color: $body-bg-dark,
    greyTint: #fff,
    boxShadow: none,
    white-bg-color: $body-bg-dark,
    notEvaluate:#1D1D1D,
  ),
);

.none {
  display: none;
}


